@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';












































































































.legitimate-hours-label {
  color: $itaa-blue;
}

.legitimate-hours {
  text-align: right;
  opacity: 0.7;
}
